import Vue from "vue"
import Router from "vue-router"
// import NProgress from "nprogress"
import "nprogress/nprogress.css"
import store from "@/store/index.js"
// import Home from '../../views/Home.vue'
// import Navi from '../../views/Navi.vue'
// import Login from '../../views/Login.vue'
// import NaviDetail from '../../views/NaviDetail.vue'
// import FreeContents from '../../views/FreeContents.vue'
// import Contents from '../../views/Contents.vue'
// import Account from '../..//views/Account.vue'
// import CreateAccount from '../..//views/CreateAccount.vue'
// import CreateAccountConfirm from '../..//views/CreateAccountConfirm.vue'
// import Support from '../..//views/Support.vue'
// import ChangePassword from '../../views/ChangePassword.vue'
// import ResetPassword from '../../views/ResetPassword.vue'
// import Reset from '../../views/Reset.vue'
// import QR from '../../views/QR.vue'
// import QA from '../../views/QA.vue'
// import NotFound from '../../views/NotFound.vue'
// import Withdraw from '../../views/Withdraw.vue'

const Login = () => import('@/views/Login.vue');
const Home = () => import('@/views/Home.vue');
const Navi = () => import('@/views/Navi.vue');
const NaviDetail = () => import('@/views/NaviDetail.vue');
const FreeContents = () => import('@/views/FreeContents.vue');
const Contents = () => import('@/views/Contents.vue');
const Account = () => import('@/views/Account.vue');
const CreateAccount = () => import('@/views/CreateAccount.vue');
const CreateAccountConfirm = () => import('@/views/CreateAccountConfirm.vue');
const Support = () => import('@/views/Support.vue');
const ChangePassword = () => import('@/views/ChangePassword.vue');
const ResetPassword = () => import('@/views/ResetPassword.vue');
const Reset = () => import('@/views/Reset.vue');
const Unlock = () => import('@/views/Unlock.vue');
const QR = () => import('@/views/QR.vue');
const QA = () => import('@/views/QA.vue');
const NotFound = () => import('@/views/NotFound.vue');
const Withdraw = () => import('@/views/Withdraw.vue');





Vue.use(Router)
const router = new Router({
  mode: "history", // URLはハッシュ形式「#～～」ではなく「/～～」とする
  linkActiveClass: "active",
  routes:[
    {path: '/',name: 'home',component: Home},
    
    {path: '/navi',name: 'navi',component: Navi },
    {path: '/free_contents',name: 'free_contents',component: FreeContents },
    {path: '/object',name: 'object',component: Contents },
    {path: '/image',name: 'image',component: Contents },
    {path: '/pdf',name: 'pdf',component: Contents },
    {path: '/sound',name: 'sound',component: Contents },
    {path: '/movie',name: 'movie',component: Contents },
    {path: '/url',name: 'url',component: Contents },
    {path: '/account',name: 'account',component: Account },
    {path: '/qr',name: 'qr',component: QR },
    {path: '/qa',name: 'qa',component: QA },
    {path: '/create_account',name: 'crete_account',component: CreateAccount },
    {path: '/create_account/confirm',name: 'crete_account_confirm',component: CreateAccountConfirm },
    {path: '/support',name: 'support',component: Support },
    {path: '/reset_password',name: 'reset_password',component: ResetPassword },
    {path: '/reset',name: 'reset',component: Reset },
    {path: '/unlock',name: 'unlock',component: Unlock },
    {path: '/change_password',name: 'change_password',component: ChangePassword },
    {path: '/login',name: 'login',component: Login },
    {path: '/navi/:id',name: 'naviDetail',component: NaviDetail },
    {path: '/withdraw',name: 'withdraw',component: Withdraw },
    {path: '/*', component: NotFound },
    
  ]
})

var no_login_url= [
  '/create_account',
  '/create_account/confirm',
  '/reset_password',
  '/reset',
  '/unlock',
  '/logo.png'

]
// router gards
router.beforeEach((to,from,next) => {
  // トークンが存在、かつログイン有効期限を過ぎてない場合、またはログイン画面の場合
  if(to.matched.some(page => {return (no_login_url.includes(page.path))})){
    next()
  }
  else if ((store.state.auth.login.sessionId && store.state.auth.login.customerId && store.state.auth.login.expire > (new Date()).getTime()) && to.matched.some(page => {return (page.path === '/login')}) ) {
    next({name: 'home'})
  }
  else if((store.state.auth.login.sessionId && store.state.auth.login.customerId && store.state.auth.login.expire > (new Date()).getTime())){
      next()
  }
  else if(to.matched.some(page => {return (page.path === '/login')})) { 
    next() 
    console.log( (new Date()).getTime())
    console.log(store.state.auth.login.expire)
  } else {
    // ログイン画面に飛ばす。ログイン後に元の画面に戻れるよう、backuriパラメーターにリダイレクト前のURLを入れる
    next({path: '/login', query: {backuri: "/"}})
  }
})

router.afterEach(() => {
})

export default router