import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          narekomu:"#4ECDC4"
          // narekomu:"302e2e"
        },
      },
    },
    icons: {
      iconfont: 'mdiSvg', // default - only for display purposes
    },
});
