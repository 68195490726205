<template>
  <v-app>
   
    <v-app-bar v-if="this.$store.state.auth.login.sessionId"
      app
      dark
      clippedLeft
      :height="60"
       style="z-index: 2000;"
      >

      <v-app-bar-nav-icon
        @click="changeNavBar"
      >
      </v-app-bar-nav-icon>
       <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
      <v-img 
        src="./assets/logo.webp"
        max-height="90%"
        max-width="130"
        class="mr-3"
      ></v-img>
       <v-spacer></v-spacer>
        <p class="mb-0 mr-2" v-if="this.$store.state.user_model.userData.userId && !$vuetify.breakpoint.xs">{{this.$store.state.user_model.userData.userId}}</p>
        <v-btn v-if="this.$store.state.auth.login.sessionId && !$vuetify.breakpoint.xs"
          depressed
          color="narekomu"
          @click="logout"
        >
        サインアウト
      </v-btn>
    
    </v-app-bar>


      <v-navigation-drawer v-if="this.$store.state.auth.login.sessionId"
        app
        dark
        clipped
        :width="200"
        :value="$vuetify.breakpoint.mdAndDown? drawer : true"
        style="z-index: 2001;"
        :expand-on-hover="$vuetify.breakpoint.lgAndUp && drawer"
      >


      <v-list
        nav
        dense
      >
        <v-list-item v-if="this.$store.state.user_model.userData.userId && $vuetify.breakpoint.xs" >
          <v-list-item-title>{{this.$store.state.user_model.userData.userId}}</v-list-item-title>
        </v-list-item>
        <v-divider v-if="this.$store.state.user_model.userData.userId && $vuetify.breakpoint.xs"></v-divider>
        <v-list-item link
            to="/"
        >
          <v-list-item-icon>
            <v-icon>{{icons.home}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>トップページ</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item  link to="/navi" :disabled="$store.state.user_model.topLoading">
          <v-list-item-icon>
            <v-icon>{{icons.mapMarker}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>ナビゲーション</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link to="/object" :disabled="$store.state.user_model.topLoading" >
          <v-list-item-icon>
              <v-icon>{{icons.video3d}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>3Dモデル</v-list-item-title>
        </v-list-item>
          <v-list-item link to="/image" :disabled="$store.state.user_model.topLoading">
          <v-list-item-icon>
              <v-icon>{{icons.image}}</v-icon>
                <!-- <v-icon>mdi-circle-small</v-icon> -->
          </v-list-item-icon>
          <v-list-item-title>画像</v-list-item-title>
        </v-list-item>

          <v-list-item link to="/pdf" :disabled="$store.state.user_model.topLoading">
          <v-list-item-icon>
              <v-icon>{{icons.filePdfBox}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>PDF</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/sound" :disabled="$store.state.user_model.topLoading">
          <v-list-item-icon>
              <v-icon>{{icons.music}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>音声</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/movie" :disabled="$store.state.user_model.topLoading">
          <v-list-item-icon>
              <v-icon>{{icons.fileVideo}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>動画</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/url" :disabled="$store.state.user_model.topLoading">
          <v-list-item-icon>
              <v-icon>{{icons.linkVariant}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>URL</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/free_contents" :disabled="$store.state.user_model.topLoading" >
          <v-list-item-icon>
              <v-icon>{{icons.alphaFCircle}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>無料コンテンツ</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

          <v-list-item link to="/account"        :disabled="$store.state.user_model.topLoading">
          <v-list-item-icon>
            <v-icon>{{icons.account}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>アカウント</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/qr"        :disabled="$store.state.user_model.topLoading">
          <v-list-item-icon>
            <v-icon>{{icons.qrcode}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>QRコードログイン</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/support" :disabled="$store.state.user_model.topLoading">
          <v-list-item-icon>
            <v-icon>{{icons.mail}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>お問合せ</v-list-item-title>
        </v-list-item>
  
      </v-list> 


      <v-divider></v-divider>
        
      <v-list-item @click="logout()" v-if="this.$store.state.user_model.userData.userId && $vuetify.breakpoint.xs" dense class="my-1">
        <v-list-item-icon>
            <v-icon>{{icons.logout}}</v-icon>
          </v-list-item-icon>
        <v-list-item-title >サインアウト</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>



    </v-navigation-drawer>



    <v-main >
         <router-view />
    </v-main>


        <v-dialog v-if="this.$store.state.auth.login.sessionId"
          v-model="$store.state.user_model.topLoading "
          persistent
          max-width="220"
        >
        
          <v-card 
            color="primary"
            elevation=0
            class="text-center "
            width="220px"
            height="220px"
          > 
            <v-progress-circular
              :indeterminate="true"
              persistent
              :size="150"
              width=10
              color="white"
              class="mt-6 "
            >Loading...
            </v-progress-circular>
          </v-card>
        </v-dialog>
  </v-app>


</template>


<script>


import axios from "axios";

import { mdiHome,mdiMapMarker,mdiVideo3d,mdiImage,mdiFilePdfBox,mdiMusic
,mdiFileVideo,mdiLinkVariant,mdiAlphaFCircle,mdiAccount,mdiQrcode,mdiMail,mdiLogout } from '@mdi/js'
export default {

  data () {
    return {
      // mini:this.$vuetify.breakpoint.smAndUp,
      icons:{
        "home":mdiHome,
        "mapMarker":mdiMapMarker,
        "video3d":mdiVideo3d,
        "image":mdiImage,
        "filePdfBox":mdiFilePdfBox,
        "music":mdiMusic,
        "fileVideo":mdiFileVideo,
        "linkVariant":mdiLinkVariant,
        "alphaFCircle":mdiAlphaFCircle,
        "account":mdiAccount,
        "qrcode":mdiQrcode,
        "mail":mdiMail,
        "logout":mdiLogout
      },
      drawer:this.$vuetify.breakpoint.lgAndUp,
      env:process.env.VUE_APP_ENV,
      loading:this.$store.state.user_model.topLoading,
      types:["navi","object","image","pdf","sound","movie","url","free_content"],
      loadedList:[],
    }
  },

  created(){
        // リロードしたとき
       if(this.$store.state.auth.login.sessionId){
        this.$store.state.user_model.topLoading = true;
        this.$store.dispatch("setUserInfo",this.$store.state.auth.login )
        .then(()=>{
          this.loadedList.push("user");
        })
        for(let i = 0 ; i < this.types.length; i++){
          this.$store.dispatch("setContentInfo",[this.types[i],this.$store.state.auth.login])
          .then((type)=>{
            this.loadedList.push(type);
            // console.log(this.loadedList)
          })
        }   
      }
  
  },

  mounted(){
     window.addEventListener('beforeunload', () => {
        this.atReload()
      })
      
  },

  watch:{

    loadedList:{
      handler(){
        if(Number(this.$store.state.user_model.userData.status)!=-1){
          if(this.loadedList.length == this.types.length+1){
                this.$store.state.user_model.topLoading =  false;

                if(this.env == "develop"){
                  console.log(this.$store.state.auth.login.sessionId)
                }
          }
        }else{
          this.logout(true);
        }
      },
      deep: true,
    }

  },


  methods: {

    atReload(){
     if(Number(this.$store.state.user_model.userData.status)!=-1){
      this.$store.dispatch("resetUserDataAtReload")
     }else{
       this.logout(true);
     }
      

    },

    //NaviBarの制御
    changeNavBar(){
      if(this.$vuetify.breakpoint.lgAndUp) this.drawer =  !this.drawer;
      else {
        this.drawer =  false;
        setTimeout(()=>{ //一旦falseにしてtrueにしないとメニュバーが反応しないため遅延を入れる
          this.drawer = true
        } ,50);
  
      }
    },

    logout (force) {

      if(!force){ if(!window.confirm("サインアウトしますか?")) return 0}

      axios({
        method: "GET",
        url:`${process.env.VUE_APP_URL}logout`,
        headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId
        },
          
      }).then(res => {
          // 成功した場合
          if (res.data) {
            console.log("Logout")
          } 
      }).catch(() => {
            // console.log(err)
      })
      this.$store.dispatch("resetUserData")
      this.$store.dispatch("resetAuthData")
      this.$router.go({path: this.$router.currentRoute.path, force: true})
      this.$store.reset()
    },


  }
  
}
</script>

<style lang="scss" >
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');


//thin
@font-face {
  font-display: optional;
  font-family: 'Noto Sans JP';
  font-weight: 100;
  src: local('Noto Sans JP'), local('Noto Sans JP-Thin'),
        url(/fonts/subset-min/NotoSansJP-Thin.woff2) format('woff2');
}

//thin
@font-face {
  font-display: optional;
  font-family: 'Noto Sans JP';
  font-weight: 200;
  src: local('Noto Sans JP'), local('Noto Sans JP-Thin'),
        url(/fonts/subset-min/NotoSansJP-DemiLight.woff2) format('woff2');
}
//light
@font-face {
  font-display: optional;
  font-family: 'Noto Sans JP';
  font-weight: 300;
  src: local('Noto Sans JP'), local('Noto Sans JP-Light'),
      url(/fonts/subset-min/NotoSansJP-Light.woff2) format('woff2');
}

//normal
@font-face {
  font-display: optional;
  font-family: 'Noto Sans JP';
  font-weight: 400;
  src: local('Noto Sans JP'), local('Noto Sans JP-Regular'),
       url(/fonts/subset-min/NotoSansJP-Regular.woff2) format('woff2');
}

//medium
@font-face {
  font-display: optional;
  font-family: 'Noto Sans JP';
  font-weight: 500;
  src: local('Noto Sans JP'), local('Noto Sans JP-Medium'),
        url(/fonts/subset-min/NotoSansJP-Medium.woff2) format('woff2');
}



//bold
@font-face {
  font-display: optional;
  font-family: 'Noto Sans JP';
  font-style:bold;
  font-weight: 700;
  src: local('Noto Sans JP'), local('Noto Sans JP-Bold'),
      url(/fonts/subset-min/NotoSansJP-Bold.woff2) format('woff2');
}

//black
@font-face {
  font-display: optional;
  font-family: 'Noto Sans JP';
  font-weight: 900;
  src: local('Noto Sans JP'), local('Noto Sans JP-Black'),
      url(/fonts/subset-min/NotoSansJP-Black.woff2) format('woff2');
}

.application {
  font-family: 'Noto Sans JP', sans-serif!important;
}


.v-application {
  font-family: 'Noto Sans JP', sans-serif!important;

  .text-h1{
    font-family: 'Noto Sans JP', sans-serif !important;
  }
  .text-h2{
    font-family: 'Noto Sans JP', sans-serif !important;
  }

  .text-h3{
    font-family: 'Noto Sans JP', sans-serif !important;
  }

  .text-h4{
    font-family: 'Noto Sans JP', sans-serif !important;
  }

  .text-h5{
    font-family: 'Noto Sans JP', sans-serif !important;
  }

  .text-h6{
    font-family: 'Noto Sans JP', sans-serif !important;
  }

  .body-1{
    font-family: 'Noto Sans JP', sans-serif !important;
  }

  .body-2{
    font-family: 'Noto Sans JP', sans-serif !important;
  }

  
  .caption{
    font-family: 'Noto Sans JP', sans-serif !important;
  }


}



</style>