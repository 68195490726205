import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

import auth from "./module/auth"
import user_model from "./module/user_model"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user_model
  },
  state: {},
  mutations: {},
  actions: {},
  plugins: [createPersistedState({
    key: "narekomu-vr-vue",     // プロジェクト単位の一意の識別子
    paths: [
      "auth.login",
      "user_model",
      "read_id_list",
    ], // ここに記述されたものはリロードしても消えない
    storage: sessionStorage  
  })]
})