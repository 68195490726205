
import axios from "axios"

const tmp = {
    userId: null,
    status: null,
    "3Dmodel":{limit:0,usage:0},
    image:{limit:0,usage:0},
    navi:{limit:0,usage:0},
    pdf:{limit:0,usage:0},
    total:{limit:0,usage:0},
    lastLogin:null,
    isLoginNotification:false,

  }


const user_model = {
  state: {
    read_id_list:[],
    userData: {
      userId: null,
      status: null,
      total:{limit:0,usage:0},
      "3Dmodel":{limit:0,usage:0},
      image:{limit:0,usage:0},
      navi:{limit:0,usage:0},
      pdf:{limit:0,usage:0},
      movie:{limit:0,usage:0},
      sound:{limit:0,usage:0},
      url:{limit:0,usage:0},
      lastLogin:null,
    },
    navi: [],
    object:[],
    image:[],
    pdf:[],
    sound:[],
    movie:[],
    url:[],
    free_content:[],
    content:false,
    auth:false,
    topLoading:false,



  },
  mutations: {

    SET_USER_INFO: (state,login) => {
      state.userData = login;
      state.userData.userId = login.userId;
      state.userData.status = login.status;
      state.userData['3Dmodel'] = login.objectCapacity;
      state.userData.image = login.imageCapacity;
      state.userData.navi = login.naviCapacity;
      state.userData.pdf = login.pdfCapacity;
      state.userData.movie = login.movieCapacity;
      state.userData.sound = login.soundCapacity;
      state.userData.url = login.urlCapacity;
      state.userData.lastLogin = login.lastLogin;
      state.userData.isLoginNotification = login.isLoginNotification;
      state.userData.total = login.total;
      state.auth = true;


    }, 

    SET_CONTENT_INFO: (state,list) => {
      let type = list[0];
      let data = list[1];
      state[type] = data;
      state.content = true;
    }, 

    RESET_USER_DATA: (state) => {
      console.log("DELETE USER DATA")
      state.userData = tmp;
      state.navi = null;
      state.object = null;
      state.image = null;
      state.sound = null;
      state.pdf = null;
      state.movie = null;
      state.url = null;
      state.free_content = [];
      state.content = false;
      state.auth = false;
    },
    
    RESET_USER_DATA_AT_RELOAD: (state) => {
      console.log("DELETE USER DATA")
      state.userData = tmp;
      state.navi = null;
      state.object = null;
      state.image = null;
      state.sound = null;
      state.pdf = null;
      state.movie = null;
      state.url = null;
      state.free_content = [];
    },

    ADD_READ_LIST: (state,id) => {
      console.log("ADD READ")
      state.read_id_list.push(id);

    },

  },
  actions: {



    async setUserInfo({ commit }, login ) {

      await axios({
        method: "GET",
        url:`${process.env.VUE_APP_URL}${login.customerId}/account`,
        headers: {
          "X-Api-Key": process.env.VUE_APP_KEY,
          "SessionId": login.sessionId
        },
      },
      ).then((response) => {
        let setData = response.data.results;
        console.log(setData)
        setData.lastLogin = login.lastLogin;
        commit("SET_USER_INFO", setData);
      }).catch(reason => {
        console.log(reason)
      });
    },

    async setContentInfo({ commit },list){
      let type = list[0];
      let login = list[1]; 
      await axios({
        method: "GET",
        url:`${process.env.VUE_APP_URL}${login.customerId}/${type}s`,
        headers: {
          "X-Api-Key": process.env.VUE_APP_KEY,
          "SessionId": login.sessionId
        },
      },
      ).then((response) => {
        let setData = response.data.results;

        if(type=="free_content"){
          Object.keys(setData).forEach(key =>{
            let tmp =  setData[key];
            for(let i = 0 ; i < tmp.length; i++){
              tmp[i].fileSizeConvert = (Number(tmp[i].fileSize)).toFixed(3) + " MB"
            }
            setData[key] = tmp;
          });
        }
        else{ 
          for(let i = 0 ; i < setData.length; i++){
            let fileSize = setData[i].fileSize;
            setData[i].fileSizeConvert = (Number(fileSize)).toFixed(3) + " MB"
          }
        }
        commit(`SET_CONTENT_INFO`,[type,setData]);
       
      }).catch(reason => {
        console.log(reason)
      });
      return type
    },

    resetUserData({ commit }) {
      commit("RESET_USER_DATA")
    },

    resetUserDataATReload({ commit }) {
      commit("RESET_USER_DATA_AT_RELOAD")
    },

    addReadList({ commit },id) {
      commit("ADD_READ_LIST",id)
    },
  }
}

export default user_model